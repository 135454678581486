<template>
  <div class="periodical">
    <el-form :model="queryInfo" class="ly-header__bg" label-width="100px">
      <el-form-item label="会议/期刊：">
        <dic-radio-button
          ref="dicRadioButtonRef"
          :code.sync="queryInfo.journal"
          query-name="journal"
          name="会议/期刊"
          @onChange="onChange"
        />
      </el-form-item>
      <el-form-item label="筛选条件：" class="filterInfo">
        <el-tag
          closable
          v-for="(item,index) in tags"
          :key="index+item.name"
          @close="closeTag(index,item.tagName)"
        >
          {{item.name}}：{{queryInfo[item.tagName]?queryInfo[item.tagName]:'不限'}}
          <span
            class="circle"
            v-if="item.hasOwnProperty('valLength')&&item.valLength>0"
          >+{{item.valLength}}</span>
        </el-tag>
      </el-form-item>
    </el-form>

    <div class="ly-container__bg">
      <div class="ly_operation">
        <div class="left">
          <el-button
            type="primary"
            icon="jr-iconjr-icon-add"
            @click="dialogVisible = true;dialogType=0"
          >添加</el-button>
        </div>
        <el-input
          placeholder="请输入会议/期刊名称"
          v-model="queryInfo.queryConditions"
          class="lager-input"
          @change="search"
        >
          <template slot="append">
            <div @click="search">搜索</div>
          </template>
        </el-input>
      </div>
      <el-table
        class="ly_table"
        :data="periodicalTable"
        :header-cell-style="{ background: '#F5F6F7', color: '#363E4D' }"
        :default-sort="{prop: 'date', order: 'descending'}"
        @sort-change="sortChange"
        :cell-style="set_cell_style"
      >
        <template slot="empty">
          <img src="@/assets/img/table.png" alt="没有数据" srcset />
        </template>
        <el-table-column prop="conference" label="会议/期刊" align="center" />
        <el-table-column prop="fullName" label="全称" align="center" width="100" />
        <el-table-column prop="count" label="论文个数" align="center" sortable="custom"/>
        <el-table-column label="操作" width="120" align="center">
          <template v-slot="{row}">
            <button-table @buttonClick="editItem(row)" content="编辑" icon="jr-iconjr-icon-edit" />
            <button-table @buttonClick="deleteItem(row)" content="删除" icon="jr-iconjr-icon-delect" />
          </template>
        </el-table-column>
      </el-table>

      <el-dialog
        ref="addVisible"
        :title="dialogType ? '编辑会议/期刊' : '新增会议/期刊'"
        :visible.sync="dialogVisible"
        width="40%"
        @close="handleClose"
        center
      >
        <el-form
          ref="periodicalRef"
          :inline="true"
          :model="periodicalObj"
          :rules="periodicalRules"
          label-width="100px"
        >
          <el-form-item label="会议/期刊：" prop="conference">
            <el-input v-model="periodicalObj.conference" placeholder="请输入" style="width:470px"/>
          </el-form-item>
          <el-form-item label="全称：" prop="fullName">
            <el-input v-model="periodicalObj.fullName" placeholder="请输入" style="width:470px" />
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button type="primary" @click="submitForm">确 定</el-button>
          <el-button @click="dialogVisible = false">取 消</el-button>
        </div>
      </el-dialog>

      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50,100,200]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import CascaderArea from '@/components/CascaderArea.vue'
import ButtonTable from '@/components/ButtonTable.vue'
import DicRadioButton from '@/components/DicRadioButton.vue'
import DicTagButton from '@/components/DicTagButton.vue'
import { pagination } from '@/mixins/pagination'
import DataTime from '@/components/DataTime.vue'
import {
  getPeriodical,
  deletePeriodical,
  updatePeriodical,
  addPeriodical,
} from '@/api/paper'
import DicCheckboxGroup from '@/components/DicCheckboxGroup.vue'
const defaultPeriodicalObj = Object.freeze({
  conference: '', // 会议/期刊
  fullName: '', // 全称
})
export default {
  name: 'Periodical',

  components: {
    ButtonTable,
    CascaderArea,
    DicRadioButton,
    DicTagButton,
    DataTime,
    DicCheckboxGroup,
  },

  mixins: [pagination],

  data() {
    return {
      queryInfo: {
        columnName:"update_time",
        order:1,
        pageNum: 1,
        pageSize: 10,
        journal: '', // 会议/期刊
      },
      periodicalObj: { ...defaultPeriodicalObj },
      periodicalRules: {
        conference: [
          { required: true, message: '请输入会议/期刊', trigger: 'blur' },
        ],
        fullName: [{ required: true, message: '请输入全称', trigger: 'blur' }],
      },
      periodicalTable: [],
      tags: [
        {
          name: '会议/期刊',
          tagName: 'journal',
        },
      ],
      dialogVisible: false,
      dialogType: 0,
    }
  },

  created() {
    this.search()
  },

  methods: {
    handleClose() {
      this.$refs.periodicalRef.resetFields()

      Object.assign(this.periodicalObj, defaultPeriodicalObj)

      this.dialogVisible = false
    },

    submitForm() {
      this.$refs.periodicalRef.validate(async (valid) => {
        if (valid) {
          let res
          if (this.dialogType) {
            res = await updatePeriodical(this.periodicalObj)
          } else {
            res = await addPeriodical(this.periodicalObj)
          }
          if (res.code === 200) {
            this.$message.success(`${this.dialogType ? '编辑' : '新增'}成功`)

            this.search()

            this.$refs.dicRadioButtonRef.getDicData()
          } else {
            this.$message.error(res.message)
          }
          this.dialogVisible = false
        }
      })
    },

    // 查询条件更变时el-tag跟着改变
    onChange(val, queryName, name, checkboxVal) {
      let childrenVal = []

      if (!this.tags.some((item) => item.tagName === queryName)) {
        if (name === '技术类别') {
          this.tags.push({
            name,
            tagName: queryName,
            valLength: 0,
          })
        } else {
          this.tags.push({
            name,
            tagName: queryName,
          })
        }
      }

      // 拿到name在tags的下标
      let result
      result = this.tags.findIndex((el) => {
        return el.name === name
      })

      switch (name) {
        case '技术类别':
          this.queryInfo.technologyCategory = [val]

          if (checkboxVal && checkboxVal.length && val) {
            this.tags[result].valLength = checkboxVal.length

            childrenVal = checkboxVal

            this.queryInfo.technologyCategory =
              this.queryInfo.technologyCategory.concat(childrenVal)
          } else {
            this.tags[result].valLength = 0
          }

          break

        default:
          break
      }

      this.search()
    },

    editItem(row) {
      Object.assign(this.periodicalObj, row)

      this.dialogVisible = true

      this.dialogType = 1
    },

    async deleteItem(row) {
      this.$confirm('确认删除吗 ?', '提示')
        .then(async () => {
          const res = await deletePeriodical({ id: row.id })
          if (res.code === 200) {
            this.$message.success('删除成功')
            this.$refs.dicRadioButtonRef.getDicData()
            this.search()
          }
        })
        .catch(() => {})
    },

    dateClick(obj) {
      if (obj.year == '0') {
        this.queryInfo.years = ''
        this.queryInfo.month = ''
        this.queryInfo.day = ''
        this.search()
      } else if (obj.month == '0') {
        this.queryInfo.years = obj.year + ''
        this.queryInfo.month = ''
        this.queryInfo.day = ''
        this.search()
      } else if (obj.day == '0') {
        this.queryInfo.years = obj.year + ''
        this.queryInfo.month = obj.month + ''
        this.queryInfo.day = ''
        this.search()
      } else {
        this.queryInfo.years = obj.year + ''
        this.queryInfo.month = obj.month + ''
        this.queryInfo.day = obj.day + ''
        this.search()
      }
    },

    searchCountry(val) {
      this.queryInfo.country = val

      this.search()
    },

    searchProvince(val) {
      this.queryInfo.province = val

      this.search()
    },

    searchCity(val) {
      if (val != '北京' && val != '天津' && val != '上海' && val != '重庆') {
        this.queryInfo.city = val

        this.search()
      }
    },

    // tag标签关闭
    closeTag(index, name) {
      this.tags.splice(index, 1)

      switch (name) {
        case 'technologyName':
          this.queryInfo.technologyName = ''
          this.queryInfo.technologyCategory = []
          break
        default:
          this.queryInfo[name] = ''
          break
      }
      this.search()
    },

    async search() {
      const res = await getPeriodical(this.queryInfo)
      if (res.code === 200) {
        this.periodicalTable = res.data.list

        this.total = res.data.total
      }
    },

    sortChange(column) {
      this.queryInfo.pageNum = 1
      if (column.prop === 'count') {
        this.queryInfo.columnName = 'count'
        if (column.order === 'ascending') {
          this.queryInfo.order = 0
        } else if (column.order === 'descending') {
          this.queryInfo.order = 1
        } else {
          this.queryInfo.order = 1
          this.queryInfo.columnName = 'update_time'
        }
      }
      this.search()
    },

    // 技术类别
    set_cell_style({ row, column }) {
      if (column.label === '技术类别') {
        return 'cursor:pointer;'
      }
    },

    // 字符串格式化成数组
    formatString(val) {
      if (!val) {
        return []
      } else if (!val.length) {
        return val
      } else {
        return val.split(',')
      }
    },
  },
}
</script>

<style lang="less" scoped>
.periodical {
  /deep/ .el-input-group__append {
    background: #1e9fff;
    color: #fff;
    border: 1px solid #1e9fff;
  }
}
</style>
